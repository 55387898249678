import './Index.scss'
import indexLogoLinkImg from './images/index--logo_link_img.png'
import indexLogoLinkImg2x from './images/index--logo_link_img@2x.png'
import indexLinkImg from './images/index--link_img.png'
import indexLinkImg2x from './images/index--link_img@2x.png'
import { Link } from 'react-router-dom'
import { Paths } from '../../const/paths'

export default function Index() {
  return (
    <>
      <div className="index">
        <a rel="noreferrer" href="https://argo-corp.net" className="index--logo_link" target={'_blank'}>
          <img src={indexLogoLinkImg} srcSet={`${indexLogoLinkImg2x} 2x`} alt="" className="index--logo_link_img" />
        </a>
        <Link to={Paths.main} className="index--link">
          <img src={indexLinkImg} srcSet={`${indexLinkImg2x} 2x`} alt="" className="index--link_img" />
        </Link>
        {/*<div className="index--app_version">version: {process.env.REACT_APP_VERSION}</div>*/}
      </div>
    </>
  )
}
