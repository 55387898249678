import './Complete.scss'
import video from './video/ED_re.mp4'
import { Link } from 'react-router-dom'
import { Paths } from '../../const/paths'
import completeBackLinkImg from './images/complete--back_link_img.png'
import completeBackLinkImg2x from './images/complete--back_link_img@2x.png'
import { useState } from 'react'

export default function Complete() {
  const [endVideo, setEndVideo] = useState(false)

  function onEndedHandler() {
    setEndVideo(true)
  }

  return (
    <>
      <div className="complete">
        <video src={video} className="complete--video" autoPlay={true} muted={true} playsInline={true} onEnded={onEndedHandler}></video>
        <Link to={Paths.index} className={endVideo ? 'complete--back_link complete--back_link-show' : 'complete--back_link'}>
          <img src={completeBackLinkImg} srcSet={`${completeBackLinkImg2x} 2x`} alt="" className="complete--back_link_img" />
        </Link>
      </div>
    </>
  )
}
