import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import Main from './pages/main/Main'
import Miss from './pages/miss/Miss'
import { createBrowserRouter, Outlet, RouterProvider, useLocation } from 'react-router-dom'
import Complete from './pages/complete/Complete'
import Index from './pages/index/Index'
import { Paths } from './const/paths'
import mainBGM from './sound/joya_kane_BGM.mp3'
import completeBGM from './sound/koto_ED.mp3'
import useSound from 'use-sound'

const router = createBrowserRouter([
  {
    path: Paths.index,
    element: <Root />,
    children: [
      {
        path: Paths.index,
        element: <Index />,
      },
      {
        path: Paths.main,
        element: <Main />,
      },
      {
        path: Paths.miss,
        element: <Miss />,
      },
      {
        path: Paths.complete,
        element: <Complete />,
      },
    ],
  },
])
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

function Root() {
  const [playMainBGM, exposedMainBGM] = useSound(mainBGM)
  const [playCompleteBGM, exposedCompleteBGM] = useSound(completeBGM)
  const location = useLocation()
  useEffect(() => {
    const stopSounds = () => {
      exposedMainBGM.stop()
      exposedCompleteBGM.stop()
    }
    stopSounds()
    switch (location.pathname) {
      case Paths.main:
        playMainBGM()
        break
      case Paths.complete:
        playCompleteBGM()
        break
      default:
        stopSounds()
        break
    }
  }, [location,exposedMainBGM, exposedCompleteBGM, playCompleteBGM, playMainBGM])
  return (
    <>
      <Outlet />
    </>
  )
}
