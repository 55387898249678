import Lottie from 'lottie-react'
import lottiRabbitJson from './lottie/rabbit.json'
import lottieMotiJson from './lottie/moti.json'
import { useRef, useState } from 'react'
import './Main.scss'
import mainLogoLinkImg from './images/main--logo_link_img.png'
import mainLogoLinkImg2x from './images/main--logo_link_img@2x.png'
import mainTitleImg from './images/main--title_img.png'
import mainTitleImg2x from './images/main--title_img@2x.png'
import mainWoodImg from './images/main--wood_img.png'
import mainWoodImg2x from './images/main--wood_img@2x.png'
import { useNavigate } from 'react-router-dom'
import { MISS_REASON } from '../miss/Miss'
import cry from './sound/voice_ei.mp3'
import don from './sound/don_SE.mp3'
import useSound from 'use-sound'
import { Paths } from '../../const/paths'

function Main() {
  const CRY_PLAYBACK_INCREMENT = 0.05
  const EXPIRED_TIME = 500 // ウサギモーション終了後から次のボタンクリックまで待ち受ける時間
  const MOTI_ANIMATION_FRAMES = 24 // 餅アニメーションをステップ実行させるときのフレーム数
  const COMPLETE_STEPS = 10 // 餅が完成するステップ数
  const CLICKED_HANDLE_DIRECTION = {
    LEFT: 'left',
    RIGHT: 'right',
    YET: 'yet',
  }
  const navigate = useNavigate()
  const [isComplete, setIsComplete] = useState(false)
  const motiAnimationFrame = useRef(0)
  const [clickedCount, setClickedCount] = useState(0)
  const leftLottieRabbitRef = useRef(null)
  const rightLottieRabbitRef = useRef(null)
  const lottieMotiRef = useRef(null)
  const isLeftPlayingRef = useRef(false)
  const isRightPlayingRef = useRef(false)
  const lastClickedHandleDirectionRef = useRef(CLICKED_HANDLE_DIRECTION.YET)
  const timer = useRef(0)
  const [playbackRate, setPlaybackRate] = useState(0.9)
  const [play_cry] = useSound(cry, { volume: 0.1, playbackRate })
  const [play_don] = useSound(don)

  const startTimer = () => {
    timer.current = setTimeout(() => {
      navigate(Paths.miss, { state: { reason: MISS_REASON.SLOW } })
    }, EXPIRED_TIME)
  }
  const stopTimer = () => {
    clearInterval(timer.current)
  }

  const leftButtonClickHandler = () => {
    if (clickedCount === COMPLETE_STEPS - 1) setIsComplete(true)
    stopTimer()
    if (lastClickedHandleDirectionRef.current === CLICKED_HANDLE_DIRECTION.LEFT) {
      navigate(Paths.miss, { state: { reason: MISS_REASON.ALTERNATE } })
      return
    }
    if (isRightPlayingRef.current) {
      navigate(Paths.miss, { state: { reason: MISS_REASON.FAST } })
      return
    }
    play_cry()
    setPlaybackRate(playbackRate + CRY_PLAYBACK_INCREMENT)
    play_don()
    lastClickedHandleDirectionRef.current = CLICKED_HANDLE_DIRECTION.LEFT
    leftLottieRabbitRef.current.goToAndPlay(0)
    isLeftPlayingRef.current = true
    lottieMotiRef.current.playSegments([motiAnimationFrame.current, motiAnimationFrame.current + MOTI_ANIMATION_FRAMES], true)
    motiAnimationFrame.current = motiAnimationFrame.current + MOTI_ANIMATION_FRAMES
    setClickedCount(clickedCount + 1)
  }

  const rightButtonClickHandler = () => {
    if (clickedCount === COMPLETE_STEPS - 1) setIsComplete(true)
    stopTimer()
    if (isLeftPlayingRef.current) {
      navigate(Paths.miss, { state: { reason: MISS_REASON.FAST } })
      return
    }
    if (lastClickedHandleDirectionRef.current === CLICKED_HANDLE_DIRECTION.RIGHT) {
      navigate(Paths.miss, { state: { reason: MISS_REASON.ALTERNATE } })
      return
    }
    play_cry()
    setPlaybackRate(playbackRate + CRY_PLAYBACK_INCREMENT)
    play_don()
    lastClickedHandleDirectionRef.current = CLICKED_HANDLE_DIRECTION.RIGHT
    rightLottieRabbitRef.current.goToAndPlay(0)
    isRightPlayingRef.current = true
    lottieMotiRef.current.playSegments([motiAnimationFrame.current, motiAnimationFrame.current + MOTI_ANIMATION_FRAMES], true)
    motiAnimationFrame.current = motiAnimationFrame.current + MOTI_ANIMATION_FRAMES
    setClickedCount(clickedCount + 1)
  }

  const leftRabbitCompleteHandler = () => {
    console.log('left complete')
    isLeftPlayingRef.current = false
    if (clickedCount !== COMPLETE_STEPS) startTimer()
  }

  const rightRabbitCompleteHandler = () => {
    console.log('right complete')
    isRightPlayingRef.current = false
    if (clickedCount !== COMPLETE_STEPS) startTimer()
  }

  function motiCompleteHandler() {}

  function fadeOutCallback() {
    navigate(Paths.complete)
  }

  return (
    <>
      <div className="main" style={{ animationName: isComplete ? 'fadeOut' : '' }} onAnimationEnd={fadeOutCallback}>
        <Lottie assetsPath="/images/lottie/moti/" className="main--moti" lottieRef={lottieMotiRef} loop={false} autoplay={false} animationData={lottieMotiJson} onComplete={motiCompleteHandler} />
        <a rel="noreferrer" href="https://argo-corp.net" className="main--logo_link" target={'_blank'}>
          <img src={mainLogoLinkImg} srcSet={`${mainLogoLinkImg2x} 2x`} alt="" className="main--logo_link_img" />
        </a>
        <img src={mainTitleImg} srcSet={`${mainTitleImg2x} 2x`} alt="" className="main--title_img" />
        <img src={mainWoodImg} srcSet={`${mainWoodImg2x} 2x`} alt="" className="main--wood_img" />
        <div className="main--cake"></div>
        <button className="main--button main--button-left" onClick={leftButtonClickHandler} disabled={clickedCount === COMPLETE_STEPS}>
          つく!
        </button>
        <button className="main--button main--button-right" onClick={rightButtonClickHandler} disabled={clickedCount === COMPLETE_STEPS}>
          つく!
        </button>
        <Lottie
          assetsPath="/images/lottie/rabbit/"
          className="main--lottie_rabbit main--lottie_rabbit-left"
          lottieRef={leftLottieRabbitRef}
          loop={false}
          autoplay={false}
          animationData={lottiRabbitJson}
          onComplete={leftRabbitCompleteHandler}
        />
        <Lottie
          assetsPath="/images/lottie/rabbit/"
          className="main--lottie_rabbit main--lottie_rabbit-right"
          lottieRef={rightLottieRabbitRef}
          loop={false}
          autoplay={false}
          animationData={lottiRabbitJson}
          onComplete={rightRabbitCompleteHandler}
        />
      </div>
    </>
  )
}

export default Main
