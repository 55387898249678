import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import './Miss.scss'
import missLinkImg from './images/miss--link_img.png'
import missLinkImg2x from './images/miss--link_img@2x.png'
import missReasonImgAlternate from './images/miss--reason_img-alternate.png'
import missReasonImgAlternate2x from './images/miss--reason_img-alternate@2x.png'
import missReasonImgFast from './images/miss--reason_img-fast.png'
import missReasonImgFast2x from './images/miss--reason_img-fast@2x.png'
import missReasonImgSlow from './images/miss--reason_img-slow.png'
import missReasonImgSlow2x from './images/miss--reason_img-slow@2x.png'
import { Paths } from '../../const/paths'

export const MISS_REASON = {
  FAST: 'fast',
  SLOW: 'slow',
  ALTERNATE: 'alternate',
}

export default function Miss() {
  const state = useLocation().state
  return (
    <>
      <div className="miss">
        <Link to={Paths.main} className="miss--link">
          {state && state.reason === MISS_REASON.ALTERNATE && <img src={missReasonImgAlternate} srcSet={`${missReasonImgAlternate2x} 2x`} alt="" className="miss--reason_img" />}
          {state && state.reason === MISS_REASON.FAST && <img src={missReasonImgFast} srcSet={`${missReasonImgFast2x} 2x`} alt="" className="miss--reason_img" />}
          {state && state.reason === MISS_REASON.SLOW && <img src={missReasonImgSlow} srcSet={`${missReasonImgSlow2x} 2x`} alt="" className="miss--reason_img" />}
          <img src={missLinkImg} srcSet={`${missLinkImg2x} 2x`} alt="" className="miss--link_img" />
        </Link>
      </div>
    </>
  )
}
